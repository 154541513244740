import React, { useEffect } from "react";
import { EntityFetcher } from "@/components/Controls";
import ProductCategoryView from "@/views/ProductCategory";
import { BrandEntity, ColorEntity } from "@/api/services/entities/entities";
import { BrandSchema, ColorSchema } from "@/api/services/entities/schemas";
import { useFavourite } from "@/modules/hooks";
import { ProductList, SkuGroup } from "@/api/services/catalog/models/Product.model";
import { useQuery } from "react-query";
import { catalogApiService, entitiesApiService } from "@/api/services";

// export type ProductCardProps = { product: ProductList };
export type ProductCardProps = {
    product: ProductList;
    skus?: SkuGroup[]; // Передаем список skus как отдельный пропс
};

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ product, skus }: ProductCardProps) => {
    const { isFavorite } = useFavourite();
    const isFav = isFavorite(product.id);

    const colorIds = product?.colors?.map((color) => color?.id) || [];
    const brandId = [String(product.attr.brand?.id)];
    // const sku = product?.attr.sku

    // console.log("Color IDs:", colorIds);
    // console.log("Brand ID:", brandId);
    console.log("SKU List:", skus);

    // const { data: fetchedProduct, isFetched } = useQuery(
    //     ["product", product.id],
    //     () => catalogApiService.getProduct(product.id), // Метод получения продукта и SKU
    //     { enabled: !!product.id }
    // );
    //
    // // Используем SKU только после того, как продукт загружен
    // useEffect(() => {
    //     if (isFetched && fetchedProduct) {
    //         const skus = fetchedProduct.skus; // Массив SKU
    //         console.log("Полученные SKU:", skus);
    //         // Ваша логика работы с SKU
    //     }
    // }, [isFetched, product]);

    // useEffect(() => {
    //     if (skus) {
    //         console.log("Полученные SKU:", skus);
    //         // Ваша логика работы с SKU
    //     }
    // }, [skus, product]);

    // const getAvailableColors = () => {
    //     if (!fetchedProduct || !fetchedProduct.skus) return [];
    //
    //     // Собираем уникальные цвета из SKU, где количество больше 0
    //     const availableColors = fetchedProduct.skus
    //         .filter((sku) => sku.quantity > 0 && sku.attr.color)
    //         .map((sku) => sku.attr.color!)
    //         .filter((color, index, self) => color && self.findIndex((c) => c.id === color.id) === index); // Убираем дубликаты, проверяя что color определён
    //
    //     return availableColors;
    // };

    // const getAvailableColorsWithSkus = () => {
    //     if (!fetchedProduct || !fetchedProduct.skus) return [];
    //
    //     const availableColorsWithSkus = fetchedProduct.skus
    //         .filter((sku) => sku.quantity > 0 && sku.attr.color)
    //         .map((sku) => ({ color: sku.attr.color!, sku }))
    //         .filter((item, index, self) => item.color && self.findIndex((c) => c.color.id === item.color.id) === index);
    //
    //     return availableColorsWithSkus;
    // };
    // const getAvailableColorsWithSkus = (skus: SkuGroup[]) => {
    //     // Проверяем, передан ли список SKU
    //     if (!skus || skus.length === 0) return [];
    //
    //     // Фильтруем доступные цвета с SKU
    //     const availableColorsWithSkus = skus
    //         .filter((sku) => sku.quantity > 0 && sku.attr.color) // Убедитесь, что есть доступный SKU и цвет
    //         .map((sku) => ({ color: sku.attr.color!, sku })) // Создаём массив объектов с цветами и SKU
    //         .filter((item, index, self) => item.color && self.findIndex((c) => c.color.id === item.color.id) === index); // Убираем дубликаты по цвету
    //
    //     return availableColorsWithSkus;
    // };

    const getAvailableColorsWithSkus = (skus: SkuGroup[]) => {
        // Проверяем, передан ли список SKU
        if (!skus || skus.length === 0) return [];

        // Фильтруем SKU с количеством > 0 и наличием цвета
        const availableColorsWithSkus = skus
            .filter((sku) => sku.quantity > 0 && sku.attr.color) // Убедитесь, что есть количество и цвет
            .map((sku) => ({ color: sku.attr.color!, sku })) // Создаём массив объектов с цветами и SKU
            .filter((item, index, self) => self.findIndex((c) => c.color.id === item.color.id) === index); // Убираем дубликаты по цвету

        // Если есть SKU с цветами, возвращаем их, иначе возвращаем первый SKU без цвета
        // return availableColorsWithSkus;
        return availableColorsWithSkus.length > 0
            ? availableColorsWithSkus
            : [{ sku: skus.find((sku) => sku.quantity > 0) }]; // Возвращаем первый SKU с количеством > 0
    };

    // Получаем доступные цвета
    // const availableColors = getAvailableColors();
    // const availableColorsWithSkus = getAvailableColorsWithSkus();
    const availableColorsWithSkus = getAvailableColorsWithSkus(skus || []);
    // const firstAvailableSkuImage = availableColorsWithSkus.length > 0 ? availableColorsWithSkus[0].sku.attr.images : "";
    // const firstAvailableSkuImage =
    //     availableColorsWithSkus.length > 0 ? availableColorsWithSkus[0].sku.attr.images?.display[0] || "" : "";
    const firstAvailableSkuImage = availableColorsWithSkus[0]?.sku?.attr?.images?.display?.[0] ?? "";

    // const firstAvailableSkuImage =
    //     availableColorsWithSkus.length > 0
    //         ? Array.isArray(availableColorsWithSkus[0].sku.attr.images?.display)
    //             ? availableColorsWithSkus[0].sku.attr.images?.display?.[0] || "" // Если массив
    //             : availableColorsWithSkus[0].sku.attr.images?.display || "" // Если одиночное изображение
    //         : "";
    console.log("First available SKU image:", availableColorsWithSkus);
    console.log("First available SKU image:", firstAvailableSkuImage);

    // useEffect(() => {
    //     if (availableColors.length > 0) {
    //         console.log("Доступные цвета:", availableColors);
    //     } else {
    //         console.log("Нет доступных цветов.");
    //     }
    // }, [availableColors]);
    // useEffect(() => {
    //     if (availableColorsWithSkus.length > 0) {
    //         console.log("Доступные цвета и SKU:", availableColorsWithSkus);
    //     } else {
    //         console.log("Нет доступных цветов.");
    //     }
    // }, [availableColorsWithSkus]);

    return (
        <ProductCategoryView.Card>
            <ProductCategoryView.Card.Wrap href={product.slug} type="top">
                {/*<ProductCategoryView.Card.Image src={product.image ? product.image[0] : ""} alt={product.title} />*/}
                <ProductCategoryView.Card.Image
                    src={firstAvailableSkuImage ? firstAvailableSkuImage : ""}
                    alt={product.title}
                />
                <ProductCategoryView.Card.Labels>
                    {product.attr.labels?.value.map((label, index) => {
                        return (
                            <ProductCategoryView.Card.Labels.Label key={product.attr.labels?.id[index]}>
                                {label}
                            </ProductCategoryView.Card.Labels.Label>
                        );
                    })}
                </ProductCategoryView.Card.Labels>
                {/*<EntityFetcher<ColorSchema, ColorEntity>*/}
                {/*    Model={ColorEntity}*/}
                {/*    queryKey="colors"*/}
                {/*    idsList={product?.colors?.map((color) => color?.id) || []}*/}
                {/*>*/}
                {/*    {({ items }) => {*/}
                {/*        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;*/}
                {/*    }}*/}
                {/*</EntityFetcher>*/}
                {/*<EntityFetcher<ColorSchema, ColorEntity>*/}
                {/*    Model={ColorEntity}*/}
                {/*    queryKey="colors"*/}
                {/*    idsList={availableColors.map((color) => String(color.id)) || []} // Изменение здесь*/}
                {/*>*/}
                {/*    {({ items }) => {*/}
                {/*        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />; // Эта строка остаётся без изменений*/}
                {/*    }}*/}
                {/*</EntityFetcher>*/}
                {/*<EntityFetcher<ColorSchema, ColorEntity>*/}
                {/*    Model={ColorEntity}*/}
                {/*    queryKey="colors"*/}
                {/*    idsList={availableColorsWithSkus.map(({ color }) => String(color.id)) || []}*/}
                {/*>*/}
                {/*    {({ items }) => {*/}
                {/*        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;*/}
                {/*    }}*/}
                {/*</EntityFetcher>*/}
                <EntityFetcher<ColorSchema, ColorEntity>
                    Model={ColorEntity}
                    queryKey="colors"
                    idsList={
                        availableColorsWithSkus.length > 0
                            ? availableColorsWithSkus
                                  .map(({ sku }) => (sku ? String(sku.attr.color?.id) : null)) // Проверяем наличие sku и color
                                  .filter((id): id is string => id !== null) // Удаляем все null значения и оставляем только строки
                            : []
                    }
                >
                    {({ items }) => {
                        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;
                    }}
                </EntityFetcher>
                {isFav ? <ProductCategoryView.Card.Favorite /> : null}
            </ProductCategoryView.Card.Wrap>
            <ProductCategoryView.Card.Wrap type="bot">
                <ProductCategoryView.Card.Features>
                    {product.attr.marks?.value.map((mark, index) => (
                        <ProductCategoryView.Card.Features.Feature key={product.attr.marks?.id[index]}>
                            {mark}
                        </ProductCategoryView.Card.Features.Feature>
                    ))}
                </ProductCategoryView.Card.Features>
                <ProductCategoryView.Card.Prices oldPrice={product.price?.rrcPrice} price={product.price?.salePrice} />
                <EntityFetcher<BrandSchema, BrandEntity>
                    Model={BrandEntity}
                    queryKey="brand"
                    idsList={[String(product.attr.brand?.id)]}
                >
                    {({ items }) => (
                        <>
                            {items.map((brand) => {
                                return (
                                    <ProductCategoryView.Card.Brand
                                        key={brand.id}
                                        src={brand.image}
                                        alt={brand.title}
                                        brandTitle={brand.title} // добавляем сюда название бренда
                                    />
                                );
                            })}
                        </>
                    )}
                </EntityFetcher>
                {product.attr.titlePrefix && (
                    <span className="catcard__titlePrefix">{product.attr.titlePrefix.value}</span>
                )}
                {/*<ProductCategoryView.Card.Prices oldPrice={product.price?.rrcPrice} price={product.price?.salePrice} />*/}
                {/*<EntityFetcher<BrandSchema, BrandEntity>*/}
                {/*    Model={BrandEntity}*/}
                {/*    queryKey="brand"*/}
                {/*    idsList={[String(product.attr.brand?.id)]}*/}
                {/*>*/}
                {/*    {({ items }) => (*/}
                {/*        <>*/}
                {/*            {items.map((brand) => {*/}
                {/*                return (*/}
                {/*                    <ProductCategoryView.Card.Brand*/}
                {/*                        key={brand.id}*/}
                {/*                        src={brand.image}*/}
                {/*                        alt={brand.title}*/}
                {/*                        brandTitle={brand.title} // добавляем сюда название бренда*/}
                {/*                    />*/}
                {/*                );*/}
                {/*            })}*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</EntityFetcher>*/}
                <ProductCategoryView.Card.Title href={product.slug}>{product.title}</ProductCategoryView.Card.Title>

                <ProductCategoryView.Card.Rating count={product?.review?.count ?? 0} avg={product?.review?.avg ?? 0} />
            </ProductCategoryView.Card.Wrap>
        </ProductCategoryView.Card>
    );
};

export default ProductCard;
